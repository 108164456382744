import React, { useEffect, useMemo, useRef } from 'react'
import Flickity from 'react-flickity-component'
import { StyledCarouselContainer, StyledFlickityComponent } from './styledCardDesignCarousel'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { FlickityRefObject } from '../../types/FlickityRefObject'
import { useFlickityVisibilityFix } from '../../hooks/UseFlickityVisibilityFix'

export type CardDesignCarouselProps = Omit<React.ComponentProps<typeof Flickity>, 'flickityRef'> & {
  //
}

export const CardDesignCarousel: React.FC<CardDesignCarouselProps> = ({ children, ...props }) => {
  const flickityRef = useRef(null) as FlickityRefObject
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const totalItems = React.Children.toArray(children).length

  useFlickityVisibilityFix(flickityRef)

  if (totalItems === 0) {
    return null
  }

  const groupCells = useMemo(() => {
    return isMobile ? 1 : (isTablet ? 2 : 3)
  }, [isMobile, isTablet])

  const groupSize = useMemo(() => {
    return totalItems >= groupCells ? groupCells : totalItems
  }, [groupCells, totalItems])

  useEffect(() => {
    if (flickityRef.current?.flkty && flickityRef.current.flkty?.options.groupCells !== groupCells) {
      flickityRef.current.flkty.options.groupCells = groupCells
      flickityRef.current.flkty.resize()
    }
  }, [flickityRef, groupCells])

  return (
    <StyledCarouselContainer>
      <StyledFlickityComponent {...props}
        ref={flickityRef}
        $itemOffset={groupSize}
        $hasNavigation={totalItems > groupCells}
        options={{
          cellAlign: 'left',
          adaptiveHeight: true,
          freeScroll: false,
          groupCells: groupCells,
          wrapAround: true,
        }}>
        {children}
      </StyledFlickityComponent>
    </StyledCarouselContainer>
  )
}
